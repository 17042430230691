import React from "react";
import "./App.css";
import Contacts from "./components/Contacts";
import LogoSvg from "./components/LogoSvg";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <LogoSvg />
        <p>
          welcome to <strong>llixgrijb.com</strong>
        </p>
        <p style={{ marginTop: -15, letterSpacing: "0.05em" }}>
          software engeneering
        </p>
        <Contacts />
      </header>
    </div>
  );
}

export default App;
