import React from "react";

export default function Contacts() {
  return (
    <div style={{ display: "flex", gap: 24 }}>
      <a href="https://t.me/llixgrijb" style={{ color: "currentColor" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            d="M9.78 18.65l.28-4.23l7.68-6.92c.34-.31-.07-.46-.52-.19L7.74 13.3L3.64 12c-.88-.25-.89-.86.2-1.3l15.97-6.16c.73-.33 1.43.18 1.15 1.3l-2.72 12.81c-.19.91-.74 1.13-1.5.71L12.6 16.3l-1.99 1.93c-.23.23-.42.42-.83.42z"
            fill="currentColor"
          />
        </svg>
      </a>
      <a href="mailto:mail@llixgrijb.com" style={{ color: "currentColor" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" fillOpacity="0" d="M12 11L4 6H20L12 11Z">
            <animate
              fill="freeze"
              attributeName="fillOpacity"
              begin="1s"
              dur="0.15s"
              values="0;0.3"
            />
          </path>
          <g
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          >
            <rect
              width="18"
              height="14"
              x="3"
              y="5"
              strokeDasharray="64"
              strokeDashoffset="64"
              rx="1"
            >
              <animate
                fill="freeze"
                attributeName="stroke-dashoffset"
                dur="0.6s"
                values="64;0"
              />
            </rect>
            <path
              strokeDasharray="24"
              strokeDashoffset="24"
              d="M3 6.5L12 12L21 6.5"
            >
              <animate
                fill="freeze"
                attributeName="stroke-dashoffset"
                begin="0.6s"
                dur="0.4s"
                values="24;0"
              />
            </path>
          </g>
        </svg>{" "}
      </a>
      <a
        href="https://www.linkedin.com/in/alexey-guryanov/"
        style={{ color: "currentColor" }}
      >
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="white"
            d="M5.086 9.711h3.002v9.031H5.086zm1.501-1.233h-.02a1.565 1.565 0 1 1 .04-3.12a1.565 1.565 0 1 1-.02 3.12m12.325 10.264H15.91v-4.83c0-1.215-.434-2.043-1.52-2.043a1.643 1.643 0 0 0-1.54 1.098a2.043 2.043 0 0 0-.1.732v5.043h-3c0-.003.04-8.184 0-9.03h3.002v1.28a2.978 2.978 0 0 1 2.705-1.493c1.975 0 3.456 1.291 3.456 4.065v5.178z"
          />
          <path
            fill="#0b66c2"
            d="M20.468 2H3.532a1.452 1.452 0 0 0-1.47 1.433v17.135c.011.8.669 1.442 1.47 1.432h16.936a1.451 1.451 0 0 0 1.47-1.432V3.433A1.451 1.451 0 0 0 20.467 2zM8.088 18.742H5.086V9.711h3.002zM6.833 8.48a1.57 1.57 0 0 1-.246-.002h-.02a1.565 1.565 0 1 1 .04-3.12a1.565 1.565 0 0 1 .226 3.122m12.079 10.262H15.91v-4.83c0-1.215-.434-2.043-1.52-2.043a1.643 1.643 0 0 0-1.54 1.098a2.06 2.06 0 0 0-.1.732v5.043h-3c0-.003.04-8.184 0-9.03h3.002v1.28a2.978 2.978 0 0 1 2.705-1.493c1.975 0 3.456 1.291 3.456 4.065v5.178z"
          />
        </svg>{" "}
      </a>
      <a href="https://github.com/excellex" style={{ color: "currentColor" }}>
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M12.001 2c-5.525 0-10 4.475-10 10a9.994 9.994 0 0 0 6.837 9.488c.5.087.688-.213.688-.476c0-.237-.013-1.024-.013-1.862c-2.512.463-3.162-.612-3.362-1.175c-.113-.288-.6-1.175-1.025-1.413c-.35-.187-.85-.65-.013-.662c.788-.013 1.35.725 1.538 1.025c.9 1.512 2.337 1.087 2.912.825c.088-.65.35-1.087.638-1.337c-2.225-.25-4.55-1.113-4.55-4.938c0-1.088.387-1.987 1.025-2.687c-.1-.25-.45-1.275.1-2.65c0 0 .837-.263 2.75 1.024a9.28 9.28 0 0 1 2.5-.337c.85 0 1.7.112 2.5.337c1.913-1.3 2.75-1.024 2.75-1.024c.55 1.375.2 2.4.1 2.65c.637.7 1.025 1.587 1.025 2.687c0 3.838-2.337 4.688-4.562 4.938c.362.312.675.912.675 1.85c0 1.337-.013 2.412-.013 2.75c0 .262.188.574.688.474A10.016 10.016 0 0 0 22 12c0-5.525-4.475-10-10-10"
          />
        </svg>{" "}
      </a>
    </div>
  );
}
